class CookieConsent extends HTMLElement {
  connectedCallback() {
    this.innerHTML = `
      <style>
      .cookie-consent {
        position: fixed;
        bottom: 8px;
        left: 20px;
        width: 300px;
        padding-top: 7px;
        height: 125px;
        color: #fff;
        line-height: 20px;
        padding-left: 10px;
        padding-right: 10px;
        font-size: 14px;
        background: #292929;
        z-index: 120;
        border-radius: 3px;
      }
      .cookie-consent.hide {
        display: none;
      }
      .allow-button {
        height: 20px;
        width: 104px;
        color: #fff;
        font-size: 12px;
        line-height: 10px;
        border-radius: 3px;
        border: 1px solid green;
        background-color: green;
      }
      #politica-de-privacidade {
        color: #3b48e6;
      }
      </style>

      <div class="cookie-consent hide">
        <span>
          Utilizamos cookies essenciais e tecnologias semelhantes conforme a nossa
          <a href="politica-de-privacidade" id="politica-de-privacidade">Política de privacidade</a>
          e, ao continuar navegando, você concorda com estas condições.
        </span>
        <div class="mt-2 d-flex justify-content-center g-2">
          <button class="allow-button mr-1">Entendi</button>
        </div>
      </div>
      `;

    const cookieConsent = this.querySelector(".cookie-consent");
    if (document.cookie.indexOf("cookie-consent=1") === -1) {
      cookieConsent.classList.remove("hide");
    }

    const allowButton = this.querySelector(".allow-button");
    allowButton.addEventListener("click", () => {
      document.cookie = "cookie-consent=1; path=/";
      cookieConsent.classList.add("hide");
    });
  }
}

window.customElements.define("cookie-consent", CookieConsent);
